import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Validation from "@/helpers/Validation";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UserModule from "@/store/module/user/UserModule";
import moment from "moment";
import ApproveDialog from "@/components/shared/Approve.vue";
let EventOwnParticipation = class EventOwnParticipation extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            add: false,
        };
        this.eventDisciplinesTable = new Map();
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: null,
            items: () => this.avialbleAmplua,
            onInput: () => {
                const amplua = this.ampluaHandbook.find((a) => a.id === this.amplua.value);
                if (amplua.name === "Спортсмен") {
                    this.category.show = true;
                    this.category.rules.push(Validation.required);
                    this.discipline.show = true;
                    this.discipline.rules.push(Validation.required);
                }
                else {
                    this.category.show = false;
                    this.category.rules.splice(0);
                    this.discipline.show = false;
                    this.discipline.rules.splice(0);
                }
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            label: "Категория",
            value: null,
            items: () => this.event.categories
                .filter((category) => {
                if (category.category.gender !== this.user.gender)
                    return false;
                const age = moment().diff(this.user.birthdate, "years");
                if (age < category.category.ageCategory.ageFrom || age > category.category.ageCategory.ageTo)
                    return false;
                return true;
            })
                .map((item) => {
                return {
                    ...item.category,
                    name: `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`,
                    id: item.id,
                };
            }),
            onInput: () => { },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.discipline = {
            ref: "discipline",
            type: "select",
            label: "Дисциплина",
            value: "",
            items: () => {
                let result = [];
                for (const sportsmanItem of this.user.profile.sportsman) {
                    const key = `${sportsmanItem.disciplineGroup}${sportsmanItem.discipline}`;
                    if (!this.eventDisciplinesTable.has(key))
                        continue;
                    result.push({
                        name: `${sportsmanItem.disciplineGroup} [${sportsmanItem.discipline}]`,
                        id: this.eventDisciplinesTable.get(key).id,
                    });
                }
                return result;
            },
            onInput: () => { },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.filters = [this.amplua, this.category, this.discipline];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get isSportsman() {
        return this.user && this.user.profile && !!this.user.profile.sportsman && this.user.profile.sportsman.length > 0;
    }
    get isJudge() {
        return this.user && this.user.profile && !!this.user.profile.judge;
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get ampluaHandbook() {
        return [...this.$store.getters[HandbookModule.types.getters.AMPLUA]].reverse();
    }
    get avialbleAmplua() {
        const amplua = [];
        if (this.isSportsman) {
            amplua.push(this.ampluaHandbook.find((a) => a.name === "Спортсмен"));
        }
        if (this.isJudge) {
            amplua.push(this.ampluaHandbook.find((a) => a.name === "Судья"));
        }
        return amplua;
    }
    get title() {
        return "Заявка на участие";
    }
    get buttonTitle() {
        return "Подать заявку";
    }
    async addParticipants() {
        const hasError = Validation.validateWithView(this.filters, this.$refs);
        if (hasError) {
            if (this.category.show && this.discipline.show) {
                return this.$notify({
                    type: "error",
                    text: "Выберите категорию и дисциплину",
                });
            }
            else {
                return this.$notify({
                    type: "error",
                    text: "Выберите амплуа",
                });
            }
        }
        const participant = {
            eventId: this.event.id,
            userId: this.user.id,
            ampluaId: parseInt(String(this.amplua.value), 10),
            categoryId: this.category.value ? parseInt(String(this.category.value), 10) : null,
            disciplineId: this.discipline.value ? parseInt(String(this.discipline.value), 10) : null,
            applicantId: null,
        };
        await this.$store.dispatch(EventModule.types.actions.APPLY_PARTICIPATION, {
            participant,
        });
        this.$notify({
            type: "success",
            title: "Вы успешно заявили себя на участие в событии",
        });
        this.$emit("update");
        this.$emit("close");
    }
    onAction() {
        this.approve.add = !this.approve.add;
    }
    async mounted() {
        for (const discipline of this.event.disciplines) {
            this.eventDisciplinesTable.set(`${discipline.disciplineGroup.name}${discipline.discipline.name}`, discipline);
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventOwnParticipation.prototype, "value", void 0);
__decorate([
    Prop({ type: Object, required: true })
], EventOwnParticipation.prototype, "event", void 0);
EventOwnParticipation = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
    })
], EventOwnParticipation);
export default EventOwnParticipation;
