import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Validation from "@/helpers/Validation";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UserModule from "@/store/module/user/UserModule";
import moment from "moment";
import ApproveDialog from "@/components/shared/Approve.vue";
let EventParticipants = class EventParticipants extends Vue {
    constructor() {
        super(...arguments);
        this.participantsTable = new Map();
        this.users = [];
        this.applicable = [];
        this.approve = {
            add: false,
            remove: false,
        };
        this.applicableTableView = {
            selected: [],
            headers: [
                {
                    text: "Ф.И.О",
                    value: "SNP",
                },
                {
                    text: "Дата рождения",
                    value: "birthdateView",
                },
                {
                    text: "Город",
                    value: "city",
                },
                {
                    text: "Анкета",
                    value: "hasProfile",
                },
            ],
            search: "",
            loading: true,
            pagesCount: 0,
            itemsPerPage: 15,
            page: 1,
            sortBy: null,
        };
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: null,
            items: () => this.ampluaHandbook,
            onInput: () => {
                const amplua = this.ampluaHandbook.find((a) => a.id === this.amplua.value);
                if (amplua.name === "Спортсмен") {
                    this.category.show = true;
                    this.category.rules.push(Validation.required);
                    this.discipline.show = true;
                    this.discipline.rules.push(Validation.required);
                }
                else {
                    this.category.show = false;
                    this.category.rules.splice(0);
                    this.discipline.show = false;
                    this.discipline.rules.splice(0);
                }
                this.setApplicable();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            label: "Категория",
            value: null,
            items: () => this.event.categories.map((item) => {
                return {
                    ...item.category,
                    name: `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`,
                    id: item.id,
                };
            }),
            onInput: () => {
                this.setApplicable();
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.discipline = {
            ref: "discipline",
            type: "select",
            label: "Дисциплина",
            value: "",
            items: () => this.event.disciplines.map((item) => {
                let name = item.disciplineGroup.name;
                if (item.discipline !== null) {
                    name += ` [${item.discipline.name}]`;
                }
                return {
                    ...item.discipline,
                    name,
                    id: item.id,
                };
            }),
            onInput: () => {
                this.setApplicable();
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.filters = [this.amplua, this.category, this.discipline];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get isCoach() {
        return this.$store.getters[UserModule.types.getters.iS_COACH];
    }
    get applicableTableItems() {
        return this.applicable.map((user) => ({
            ...user,
            birthdateView: moment(user.birthdate).format("YYYY-MM-DD"),
            SNP: `${user.surname} ${user.name} ${user.patronymic}`,
            city: user.address.city ? `${user.address.city.name}` : `Не указан`,
            hasProfile: !!user?.profile ? "Заполнена" : "Не заполнена",
        }));
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get ampluaHandbook() {
        return [...this.$store.getters[HandbookModule.types.getters.AMPLUA]].reverse();
    }
    get title() {
        if (this.isRecall)
            return "Ототзвать участников";
        return "Заявление участников";
    }
    get buttonTitle() {
        if (this.isRecall)
            return "Отозвать";
        return "Заявить";
    }
    get noTableData() {
        if (this.isRecall)
            return "Вы никого не заявляли на данное событие";
        return "По заданным фильтрам пользователи не найдены";
    }
    setApplicable() {
        this.applicable.splice(0);
        this.applicableTableView.selected.splice(0);
        const pickedAmplua = this.ampluaHandbook.find((a) => a.id === this.amplua.value);
        const pickedCategory = this.event.categories.find((item) => item.id === this.category.value);
        const pickedDiscipline = this.event.disciplines.find((i) => i.id === this.discipline.value);
        for (const user of this.users) {
            const isSportsman = user.profile && user.profile.sportsman && user.profile.sportsman.length > 0;
            const isCoach = user.profile && !!user.profile.coach;
            const isJudge = user.profile && !!user.profile.judge;
            if (pickedAmplua) {
                if (pickedAmplua.name === "Спортсмен" && !isSportsman)
                    continue;
                if (pickedAmplua.name === "Тренер" && !isCoach)
                    continue;
                if (pickedAmplua.name === "Судья" && !isJudge)
                    continue;
                if (pickedAmplua.name === "Спортсмен") {
                    if (pickedCategory) {
                        if (user.gender !== pickedCategory.category.gender)
                            continue;
                        const age = moment().diff(user.birthdate, "years");
                        if (age < pickedCategory.category.ageCategory.ageFrom || age > pickedCategory.category.ageCategory.ageTo) {
                            continue;
                        }
                    }
                    if (pickedDiscipline) {
                        const userHasDiscipline = user.profile.sportsman.find((i) => i.discipline === pickedDiscipline.discipline.name);
                        if (!userHasDiscipline)
                            continue;
                    }
                }
            }
            const participant = this.participantsTable.get(user.id);
            if (this.isRecall) {
                if (!!participant && participant.applicantId === this.user.id) {
                    this.applicable.push(user);
                    continue;
                }
                continue;
            }
            else {
                if (!!participant)
                    continue;
                this.applicable.push(user);
            }
        }
    }
    async removeParticipants() {
        if (this.applicableTableView.selected.length === 0) {
            return this.$notify({
                text: "Не выбран ни один участник",
            });
        }
        const participantsIds = this.applicableTableView.selected.map((user) => this.participantsTable.get(user.id).id);
        await this.$store.dispatch(EventModule.types.actions.RECALL_PARTICIPANTS, {
            eventId: this.event.id,
            participantsIds,
        });
        this.applicableTableView.selected.splice(0);
        await this.loadAndSetApplicable();
        this.$notify({
            type: "success",
            title: "Выбранные участники успешно отозванны",
        });
        this.$emit("update");
    }
    async addParticipants() {
        if (this.applicableTableView.selected.length === 0) {
            return this.$notify({
                text: "Не выбран ни один участник",
            });
        }
        const hasError = Validation.validateWithView(this.filters, this.$refs);
        if (hasError) {
            if (this.category.show && this.discipline.show) {
                return this.$notify({
                    type: "error",
                    text: "Выберите категорию и дисциплину",
                });
            }
            else {
                return this.$notify({
                    type: "error",
                    text: "Выберите амплуа",
                });
            }
        }
        const participants = this.applicableTableView.selected.map((user) => ({
            eventId: this.event.id,
            userId: user.id,
            ampluaId: parseInt(String(this.amplua.value), 10),
            categoryId: this.category.value ? parseInt(String(this.category.value), 10) : null,
            disciplineId: this.discipline.value ? parseInt(String(this.discipline.value), 10) : null,
            applicantId: this.user.id,
        }));
        await this.$store.dispatch(EventModule.types.actions.DECLARE_PARTICIPANTS, {
            participants,
        });
        this.applicableTableView.selected.splice(0);
        await this.loadAndSetApplicable();
        this.$notify({
            type: "success",
            title: "Участники успешно заявлены",
        });
        this.$emit("update");
    }
    onAction() {
        if (this.isRecall) {
            this.approve.remove = !this.approve.remove;
        }
        else {
            this.approve.add = !this.approve.add;
        }
    }
    async loadAndSetApplicable() {
        this.users = await this.$store.dispatch(UserModule.types.actions.GET_RELATED_REGION_USERS);
        const event = await this.$store.dispatch(EventModule.types.actions.LOAD_EVENT, { id: this.event.id });
        this.participantsTable.clear();
        event.participants.map((p) => this.participantsTable.set(p.userId, p));
        this.setApplicable();
    }
    async mounted() {
        if (!this.isCoach)
            return;
        await this.loadAndSetApplicable();
        this.applicableTableView.loading = false;
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventParticipants.prototype, "value", void 0);
__decorate([
    Prop({ type: Object })
], EventParticipants.prototype, "event", void 0);
__decorate([
    Prop({ type: Boolean })
], EventParticipants.prototype, "isRecall", void 0);
EventParticipants = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
    })
], EventParticipants);
export default EventParticipants;
